<template>
  <div class>
    <div v-background-3 class="p-3 rounded mt-3 mt-md-0 shadow-sm">
      <div class="d-flex justify-content-end align-items-center flex-column flex-lg-row">
        <download-excel :data="docsPourExport" class="btn btn-primary cursor-pointer mt-1 mb-1" name="lda.xls">
          Exporter (.xls)
          <span class="badge badge-warning">{{ docsPourExport.length }}</span>
        </download-excel>
        <modal ref="modalDoc" title="Ajouter" subtitle="Ajouter un document" id="AddDoc" @store="storeDocument">
          <base-form-row row>
            <base-input v-model="file.nom" inputText="Nom" :errors="feedback.nom"></base-input>
          </base-form-row>
          <base-form-row row>
            <BaseSelect
              inputText="Type"
              v-model.number="file.type_id"
              :options="contextData.types"
              :errors="feedback.type_id"
            ></BaseSelect>
          </base-form-row>
          <base-form-row row>
            <base-input-number
              v-model="file.version"
              :errors="feedback.version"
              inputText="Version"
            ></base-input-number>
          </base-form-row>
          <base-form-row row>
            <base-input v-model="file.description" inputText="Description" :errors="feedback.description"></base-input>
          </base-form-row>
        </modal>
      </div>
      <div class="d-flex flex-column flex-md-row">
        <BaseSelect
          inputText="Filter par documents d'accueil"
          v-model.number="filterAccueil"
          :options="contextData.accueils"
          class="w-100 mr-3"
          :disabled="false"
        ></BaseSelect>
        <InputSearch class="w-100 mr-3" text="Filtrer" v-model="filterText" @reset="filterText = ''"></InputSearch>
      </div>
    </div>
    <div class="d-flex align-items-center mt-2" v-if="loading">
      <Loader class="m-5"></Loader>
      <h2 class="m-0">Chargement...</h2>
    </div>
    <div v-if="!loading" class="mt-2">
      <div v-for="file in documentsFiltered" :key="file.id">
        <liste-item
          :file="file"
          :contextData="contextData"
          :loadingUpdate="loadingUpdate"
          :statut="statut"
          @delete="deleteDocument"
          @update="updateDocument"
        ></liste-item>
      </div>
    </div>
  </div>
</template>
<script>
//import BaseToolBar from "@/components/bases/ToolBar.vue";
import ListeItem from "@/components/documents/ListeItem.vue";
import Loader from "@/components/bases/Loader.vue";
import BaseFormRow from "@/components/bases/FormRow.vue";
import BaseInput from "@/components/bases/Input.vue";
import BaseSelect from "@/components/bases/Select.vue";
import BaseInputNumber from "@/components/bases/InputNumber.vue";
//import BaseInputArea from "@/components/bases/InputArea.vue";
import Modal from "@/components/bases/Modal.vue";
import InputSearch from "@/components/bases/InputSearch.vue";

export default {
  components: {
    //BaseToolBar,
    ListeItem,
    Loader,
    BaseInput,
    BaseSelect,
    BaseInputNumber,
    //BaseInputArea,
    Modal,
    BaseFormRow,
    InputSearch,
  },
  data() {
    return {
      files: [],
      file: {
        type_id: null,
        raccourcis: "",
        categories: "",
        activites: "",
        version: 0,
        description: "",
        accessible_collabs: false,
        accessible_managers: false,
        accessible_directors: false,
      },
      loading: false,
      loadingUpdate: false,
      contextData: { types: {}, raccourcis: {} },
      feedback: {},
      statut: null,
      filterText: "",
      filterAccueil: null,
    };
  },
  methods: {
    getRaccourcis: function () {
      this.loading = true;
      this.$http
        .get("/documents_qsser/raccourcis")
        .then((response) => (this.contextData.raccourcis = response.data))
        .catch((error) => console.log(error))
        .finally(() => (this.loading = false));
    },
    getTypes: function () {
      this.loading = true;
      this.$http
        .get("/documents_qsser/types")
        .then((response) => (this.contextData.types = response.data))
        .catch((error) => console.log(error))
        .finally(() => (this.loading = false));
    },
    getAccueils: function () {
      this.loading = true;
      this.$http
        .get("/documents_qsser/accueils")
        .then((response) => (this.contextData.accueils = response.data))
        .catch((error) => console.log(error))
        .finally(() => (this.loading = false));
    },
    getDocuments: function () {
      this.loading = true;
      this.$http
        .get("/documents_qsser", {
          params: {
            q: this.$route.params.request,
          },
        })
        .then((response) => (this.files = response.data))
        .catch((error) => console.log(error))
        .finally(() => (this.loading = false));
    },
    updateDocument: function (file) {
      this.loadingUpdate = true;
      this.$http
        .post("/documents_qsser/" + file.id, file)
        .then((response) => (this.statut = response.data))
        .catch((error) => this.errHandler(error))
        .finally(() => {
          this.loadingUpdate = false;
        });
    },
    deleteDocument: function (file) {
      if (!window.confirm("Confirmer la suppression de l'élément ?")) return null;
      this.loadingUpdate = true;
      this.$http
        .delete("/documents_qsser/" + file.id)
        .then((response) => {
          this.statut = response.data;
          const idx = this.files.indexOf(file);
          this.files.splice(idx, 1);
        })
        .catch((error) => this.errHandler(error))
        .finally(() => {
          this.loadingUpdate = false;
        });
    },
    errHandler: function (error) {
      if (error.response.status === 422) {
        this.feedback = error.response.data.error;
        return;
      }
    },
    storeDocument: function () {
      this.loading = true;
      this.$http
        .put("/documents_qsser", this.file)
        .then(() => {
          this.file = {};
          this.feedback = {};
          this.$refs.modalDoc.hide();
          this.getDocuments();
          this.$router.push("/documents/all");
        })
        .catch((error) => console.log(error))
        .finally(() => (this.loading = false));
    },

    libCategorieAccueil(id) {
      switch (id) {
        case 1:
          return "RH";
        case 2:
          return "Mutuelle";
        case 3:
          return "Q3SER";
        default:
          return "";
      }
},

  },
  computed: {
    documentsFiltered() {
      let rep = this.files;

      if (this.filterText) {
        rep = rep.filter((file) => {
          if (file.nom === null) file.nom = "";
          if (file.activites === null) file.activites = "";
          if (file.identifiant === null) file.identifiant = "";
          if (file.categories === null) file.categories = "";
          if (file.raccourcis === null) file.raccourcis = "";

          const transformText = (text) =>
            text
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "");

          return (
            transformText(file.nom).includes(transformText(this.filterText)) ||
            transformText(file.activites).includes(transformText(this.filterText)) ||
            transformText(file.identifiant).includes(transformText(this.filterText)) ||
            transformText(file.categories).includes(transformText(this.filterText)) ||
            transformText(file.raccourcis).includes(transformText(this.filterText))
          );
        });
      }

      if (this.filterAccueil) {
        rep = rep.filter((file) => file.accueils.some((ac) => ac.accueil_id == this.filterAccueil));
      }

      return rep;
    },
    

    docsPourExport() {
      let resp = [];
      if (this.files.length < 1) return [];
      this.files.forEach((doc) => {
        if (doc.nom !== "vide")
          resp.push({
            id: doc.id,
            identifiant: doc.identifiant,
            nom: doc.nom,
            description: doc.description,
            date: doc.datedoc,
            externe: doc.externe,
            révision: doc.version,
            accueil: doc.accessible_collabs,
            categorie_accueil: this.libCategorieAccueil(doc.categorie_accueil),
            raccourcis: doc.raccourcis ? doc.raccourcis.replace(";", ", ") : "",
            categories: doc.categories ? doc.categories.replace(";", ", ") : "",
          });
      });
      return resp;
    },
  },
  mounted() {
    this.getAccueils();
    this.getTypes();
    this.getDocuments();
    this.getRaccourcis();
  },
  watch: {
    $route: function () {
      this.getDocuments();
      this.filterText = "";
    },
  },
};
</script>
